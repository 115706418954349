/* ContactForm.css */

/* Import the Poppins Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.create {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
}

.create h3 {
  font-size: 2rem;
  color: #000;
  text-align: center;
  margin-bottom: 20px;
}

.create label {
  display: block;
  font-size: 1rem;
  color: #555;
  margin-bottom: 5px;
}

.create input,
.create textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.create textarea {
  resize: vertical;
  height: 150px;
}

.create button {
  width: 100%;
  padding: 10px;
  background-color: #007BFF; /* Blue color for the button */
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create button:hover {
  background-color: #0056b3; /* Darker blue for the hover effect */
}

.create .error {
  color: red;
  margin-top: 10px;
  text-align: center;
}
