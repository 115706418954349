/* Employment.css */

/* Import the Poppins Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.employment {
  padding: 40px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

h2 {
  font-size: 2.5rem;
  color: #000000;
  margin-bottom: 20px;
}

p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
}

.benefits-list {
  list-style-type: none;
  padding: 0;
}

.benefits-list li {
  background: #f8f8f8;  /* Light purple background for list items */
  color: #000000;  /* Darker purple for text */
  margin: 10px 0;
  padding: 15px;
  border-radius: 8px;
  font-size: 1.1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background 0.3s ease;
}

.benefits-list li:hover {
  /*background: #e0d4f8;  /* Slightly darker purple on hover */
  transform: translateY(-5px);
}
