/* Navbar.css */

/* Import the Poppins Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

header {
  background: #f0f0f0;  /* Light grey background for a clean look */
  color: #6a0dad;  /* Default text color to a lighter purple */
  padding: 10px 0;  /* Increased padding for a more spacious look */
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: box-shadow 0.3s ease;  /* Smooth transition for shadow */
}

header.scrolled {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);  /* Slightly larger shadow for depth when scrolled */
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;  /* Increased padding for better spacing */
}

.branding {
  display: flex;
  align-items: center;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

h1 {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 1.8rem;  /* Slightly larger font size */
  font-weight: 700;  /* Bold weight for the heading */
  color: #000000;  /* Black color for the heading text */
  margin-right: 10px;  /* Space between text and logo */
}

.logo {
  height: 50px;  /* Increased height for better visibility */
  width: auto;   /* Maintain aspect ratio */
  padding-right: 20px;
}

nav {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 5px;  /* Increased gap for better spacing */
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin: 0;
}

.nav-links a {
  color: #000000;  /* Black color for links */
  text-decoration: none;
  padding: 6px 8px;  /* Increased padding for larger clickable area */
  transition: background 0.3s ease, color 0.3s ease;  /* Smooth transition for hover effects */
  border-radius: 5px;  /* Rounded corners for a modern look */
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;  /* Adjusted font size for links */
}

.nav-links a:hover {
  background: #d3d3d3;  /* Slightly darker grey background on hover */
  color: #000000;  /* Keep black text color on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    padding: 0 20px;  /* Adjusted padding for smaller screens */
  }

  .nav-links {
    flex-direction: column;  /* Stack links vertically on smaller screens */
    align-items: flex-start;  /* Align to the start */
    gap: 15px;  /* Smaller gap between links */
  }

  .nav-links a {
    padding: 10px 0;  /* Adjust padding for vertical layout */
  }
}
