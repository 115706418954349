/* Home.css */

.home {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items to the start for higher positioning */
    min-height: calc(100vh - 80px); /* Full viewport height minus the navbar height */
    padding: 0px 40px; /* Padding around the content */
    background-color: #f0f0f0; /* Light background color */
}

.home-content {
    max-width: 50%;
    margin-top: 0px; /* Adjust top margin to fine-tune the height */
}

.home-title {
    font-size: 3rem;
    font-weight: 700;
    color: #000000; /* Black color for the title */
    margin-bottom: 20px;
}

.home-description {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #333; /* Darker text color */
    margin-bottom: 40px;
}

.home-image {
    max-width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px; /* Align the image with the content */
}

.home-image img {
    max-width: 100%;
    height: auto;
}
