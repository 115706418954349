/* Services.css */

/* Import the Poppins Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.services {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  color: #333;
}

.services h2 {
  font-size: 2.5rem;
  color: #000000;  /* Black color */
  text-align: center; /* Right-align the heading */
  margin-bottom: 20px;
}

.services p.intro {
  font-size: 1.2rem;
  line-height: 1.6;
  text-align: right;  /* Right-align the intro text */
  margin-bottom: 40px;
}

.service-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.service-item {
  flex: 1 1 calc(33.333% - 20px);
  background: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-item h3 {
  font-size: 1.5rem;
  color: #000000;  /* Black color */
  margin-bottom: 10px;
}

.service-item p {
  font-size: 1rem;
  color: #555;
}

.service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .service-item {
    flex: 1 1 100%;
  }
}
